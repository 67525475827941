.desktop {
    width: 100%;
    height: 100vh;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.content-mobile {
    width:90%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.mobile {
    width: 100%;
    height: 100vh;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    align-items: center;
    justify-content: center;
    background-color: #d1d1e0;
}

h1 {
    color: #000639;
}

h2 {
    color: #000639;
}

p {
    color: #000639;
}

.muiIcon {
    color: #000639;
}

.navbar {
    display: flex;
}

#userPortal {
    background-color: honeydew;
}

.navBarItem {
    text-decoration: none;
    color: #031d4d;
    font-weight: bolder;
    padding: 0 10px;
}

.navBarIcon {
    color: #031d4d;
}

.navBarButton {
    text-decoration: none;
    color: #031d4d;
    font-weight: bolder;
}

.navLink {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.3rem;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}
.navLink:active {
    color: #031d4d;
}
.navLink:hover {
    color: #031d6e;
}

.blogBox {
    position: relative;
    background: #fff;
    text-align: center;
    height: 40vh;
    line-height: 60px;
}

.blogBox-mobile {
    background: #fff;
    text-align: center;
    height: 50vh;
    line-height: 60px;
}

.blogBox-title {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    line-height: normal;
    background-color: #cdfff1;
}

.blogBox-title-mobile {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    line-height: normal;
    background-color: #cdfff1;
}

.blogBox-summary {
    font-size: small;
    padding: 10px 20px;
    line-height: normal;
}

.blogBox-summary-mobile {
    height: 80%;
    font-size: small;
    padding: 10px 20px;
    line-height: normal;
}

img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    overflow: hidden;
}

.blogBox img {
    position: absolute;
    height: 25vh;
    width: 100%;
    left: 0;
    bottom: 0;
}

.blogBox:hover {
    background: #ededed;
}

.homeIcon {
    position: absolute;
    top: 10px;
    left: 20px;
}

#signupButton {
    background: #1ad6bd;
}

#menuIcon {
    padding: 10px 20px;
}

.project-section {
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 20px 0;
}

.container {
    display: grid;
    grid-template-rows: fit-content() fit-content() fit-content();
    box-sizing: border-box;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    align-items: center;
    justify-content: center;
    max-width: 60em;
    padding: 10px;
  }

.post-content {
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 0 20px;
    background-color: #b7efea;
}

#post-return {
    float: right;
}

.comment-section {
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 20px 20px;
}

.comment-box {
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 20px 20px;
}

.comment-box-content {
    background-color: #15AD98;
}

.is-scrollLocked {
    overflow: hidden;
}

.is-momentumScrollable {
    -webkit-overflow-scrolling: touch;
 }

 .quill {
    height: 100%;
    width: 100%;
 }