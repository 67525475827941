.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
}

.content {
  display: flex;
  max-width: 800px;
  padding: 20px 0;
  margin: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  justify-content: center;
  align-items: flex-start;
}

.personal-info {
  flex: 1;
  max-width: 33%;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.personal-info h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.personal-info p {
  font-size: 14px;
  margin: 5px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%; /* Ensure it doesn’t exceed the parent’s width */
}

.skill-group {
  margin-top: 20px;
}

.skill-group h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.skill-category {
  margin-bottom: 10px;
}

.skill-category h4 {
  font-size: 16px;
  margin-bottom: 4px;
  color: #555;
  word-wrap: break-word;
  overflow-wrap:  revert-layer;
  white-space: normal;
  max-width: 100%; /* Ensure it doesn’t exceed the parent’s width */
}

.skill-category ul {
  list-style-type: none;
  padding-left: 0;
}

.skill-category li {
  font-size: 14px;
  margin-bottom: 3px;
}

.main-content {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.professional-summary {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.professional-summary h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.professional-summary p {
  font-size: 16px;
}

.job {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.job h2 {
  font-size: 20px;
  margin-bottom: 5px;
}

.job h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #555;
}

.job p {
  margin: 5px 0;
}

.job h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.accomplishments {
  margin-bottom: 10px;
}

.accomplishment {
  margin-bottom: 5px;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.technology {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.education {
  margin-top: 20px;
}

.education h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.education p {
  font-size: 16px;
  margin: 5px 0;
}